const Download = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 10.5H14V3.5H10V10.5H6L12 16.5L18 10.5ZM5 18.5V20.5H19V18.5H5Z" />
  </svg>
);
Download.displayName = 'IconsFileDownload';
export default Download;
