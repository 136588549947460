import Head from 'next/head';
import React from 'react';

interface IProps {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  imgURL?: string;
}

const View = (props: IProps) => {
  return (
    <Head>
      {!!props.title && (
        <>
          <title>{props.title}</title>
          <meta key="ogtitle" property="og:title" content={props.title} />
          <meta name="twitter:title" content={props.title} />
        </>
      )}

      {!!props.description && (
        <>
          <meta name="description" content={props.description} />
          <meta
            key="ogdescription"
            property="og:description"
            content={props.description}
          />
          <meta name="twitter:description" content={props.description} />
        </>
      )}

      {!!props.imgURL && (
        <>
          <meta key="ogimage" property="og:image" content={props.imgURL} />
          <meta
            key="twitterimage"
            name="twitter:image"
            content={props.imgURL}
          />
        </>
      )}
    </Head>
  );
};
View.displayName = 'CustomMetatag';
export default View;
