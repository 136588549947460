import { keyframes, styled } from '@/stitches.config';

const spin = keyframes({
  '0%': { transform: 'rotate(0)' },
  '100%': { transform: 'rotate(360deg)' },
});

const LoadingWrapper = styled('div', {
  width: '24px',
  height: '24px',
  position: 'absolute',
  top: 'calc(50% - 12px)',
  left: 'calc(50% - 12px)',
  backgroundPosition: 'center center',
  transition: 'opacity 250ms ease 0s',
  opacity: '1',
  animation: `${spin} 0.5s infinite linear`,
});

export const Loading = () => (
  <LoadingWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs>
        <radialGradient
          id="a"
          cx="97.483%"
          cy="28.573%"
          r="168.336%"
          fx="97.483%"
          fy="28.573%"
          gradientTransform="matrix(.9983 -.05822 .02352 .40335 -.005 .227)"
        >
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#FFF" stopOpacity=".816" offset="18.426%" />
          <stop stopColor="#FFF" stopOpacity="0" offset="100%" />
        </radialGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path
          stroke="url(#a)"
          strokeWidth="2"
          d="M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="48.33 69.117"
        />
      </g>
    </svg>
  </LoadingWrapper>
);
Loading.displayName = 'SharedElementLoading';
