import Link from 'next/link';
import React, { useCallback, useMemo } from 'react';

import { Icon } from '@/components/shared/element/icons';
import { Loading } from '@/components/shared/element/loading';
import { Text } from '@/components/shared/element/text';
import { CSS, styled } from '@/stitches.config';
import { IIcon } from '@/types/shared';
import { ITest, ITracking } from '@/types/tracking';

const ButtonStyled = styled('button', {
  outline: 'none',
  border: 0,
  bs: 'none',
  backgroundColor: 'transparent',
  padding: '$space-0',
  margin: '$space-0',
  boxSizing: 'border-box',
  us: 'none',
  verticalAlign: 'middle',
  cursor: 'pointer',
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '$space-2',
  textAlign: 'center',
  textDecoration: 'none',
  borderRadius: '$rounded-1',
  '*, ::before': {
    pe: 'none',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: '$space-0',
    borderRadius: 'inherit',
    backgroundColor: 'var(--bg-color)',
    transition:
      'background-color var(--transition-duration) var(--transition-easing)',
  },
  '& i, & span': {
    color: 'var(--icon-color)',
  },
  '&:disabled': {
    pe: 'none',
    '&::before': {
      backgroundColor: '$gray300',
    },
    '& i, & span': {
      color: 'var(--input-color-disabled)',
    },
  },
  '@hover': {
    '&:hover, [data-hover]:hover &': {
      '&::before': {
        backgroundColor: 'var(--hover-bg-color)',
      },
      '& i, & span': {
        color: 'var(--hover-icon-color)',
      },
    },
  },
  variants: {
    size: {
      sm: {
        py: '$space-1',
        px: '$space-4',
        minWidth: '$size-18',
        minHeight: '$size-8',
      },
      md: {
        py: '$space-1',
        px: '$space-4',
        minWidth: '$size-22',
        minHeight: '$size-10',
      },
      lg: {
        py: '$space-1',
        px: '$space-4',
        minWidth: '$size-22',
        minHeight: '$size-10',
        '@md': {
          py: '$space-2',
          px: '$space-6',
          minWidth: '$size-26',
          minHeight: '$size-12',
        },
      },
      xl: {
        py: '$space-1',
        px: '$space-6',
        minWidth: '$size-30',
        minHeight: '$size-12',
        '@md': {
          py: '$space-2',
          px: '$space-6',
          minWidth: '$size-56',
          minHeight: '$size-14',
        },
      },
    },
    variant: {
      'button-glost': {
        '--bg-color': 'rgba(255, 255, 255, 0.1)',
        '--hover-bg-color': 'var(--colors-white)',
        '--icon-color': 'var(--colors-white)',
        '--hover-icon-color': 'var(--colors-gray900)',
      },
      'button-red': {
        '--bg-color': 'var(--colors-primary)',
        '--hover-bg-color': 'var(--colors-primary100)',
        '--icon-color': 'var(--colors-white)',
        '--hover-icon-color': 'var(--colors-white)',
      },
      'button-black': {
        '--bg-color': 'var(--colors-black)',
        '--hover-bg-color': 'var(--colors-gray900)',
        '--icon-color': 'var(--colors-white)',
        '--hover-icon-color': 'var(--colors-white)',
      },
      'button-gray': {
        '--bg-color': 'var(--colors-gray900)',
        '--hover-bg-color': 'var(--colors-black)',
        '--icon-color': 'var(--colors-white)',
        '--hover-icon-color': 'var(--colors-white)',
      },
      'button-gray-light': {
        '--bg-color': 'var(--colors-gray300)',
        '--hover-bg-color': 'var(--colors-gray900)',
        '--icon-color': 'var(--colors-gray900)',
        '--hover-icon-color': 'var(--colors-white)',
      },
      'button-white': {
        '--bg-color': 'var(--colors-white)',
        '--hover-bg-color': 'var(--colors-gray900)',
        '--icon-color': 'var(--colors-gray900)',
        '--hover-icon-color': 'var(--colors-white)',
      },
      'button-white-text-blue': {
        '--bg-color': 'var(--colors-white)',
        '--hover-bg-color': 'var(--colors-blue)',
        '--icon-color': 'var(--colors-blue)',
        '--hover-icon-color': 'var(--colors-white)',
      },
      'button-blue': {
        '--bg-color': 'var(--colors-blue)',
        '--hover-bg-color': 'var(--colors-white)',
        '--icon-color': 'var(--colors-white)',
        '--hover-icon-color': 'var(--colors-blue)',
      },
      'button-white-text-green': {
        '--bg-color': 'var(--colors-white)',
        '--hover-bg-color': 'var(--colors-green)',
        '--icon-color': 'var(--colors-green)',
        '--hover-icon-color': 'var(--colors-white)',
      },
      'button-green': {
        '--bg-color': 'var(--colors-green)',
        '--hover-bg-color': 'var(--colors-white)',
        '--icon-color': 'var(--colors-white)',
        '--hover-icon-color': 'var(--colors-green)',
      },
    },
    border: {
      true: {
        '&::before': {
          backgroundColor: 'transparent',
          border: '2px solid var(--bg-color)',
          transition:
            'border-color var(--transition-duration) var(--transition-easing), background-color var(--transition-duration) var(--transition-easing)',
        },
        '& i, & span': {
          color: 'var(--bg-color)',
        },
        '@hover': {
          '&:hover, [data-hover]:hover &': {
            '&::before': {
              borderColor: 'var(--bg-color)',
              backgroundColor: 'var(--bg-color)',
            },
            '& i, & span': {
              color: 'var(--icon-color)',
            },
          },
        },
        '&:disabled': {
          '&::before': {
            backgroundColor: '$gray300',
            borderColor: '$gray300',
          },
          '& i, & span': {
            color: 'var(--input-color-disabled)',
          },
        },
      },
    },
    isShadow: {
      true: {
        bs: '$shadow100',
      },
    },
    isLoading: {
      true: {
        pointerEvents: 'none !important',
        '& i, & span': {
          opacity: 0,
          transitionProperty: 'color, opacity',
        },
      },
    },
    isPointerNone: {
      true: {
        pointerEvents: 'none !important',
      },
    },
    isFull: {
      true: {
        width: '100%',
      },
    },
    iconPosition: {
      leading: {
        flexDirection: 'row',
      },
      trailing: {
        flexDirection: 'row-reverse',
      },
    },
    isMinWidth: {
      true: {
        width: '100%',
        '@md': {
          width: 'auto',
        },
      },
    },
    isIconLarge: {
      true: {
        '& i': {
          size: '$size-12',
          minWidth: '$size-12',
          '@md': {
            size: '$size-14',
            minWidth: '$size-14',
          },
        },
      },
    },
    isTransparent: {
      true: {
        columnGap: '$space-4',
        padding: '$space-0',
        borderRadius: '$rounded-0',
        '&::before': {
          content: 'none',
        },
        '& i, & span': {
          filter: 'drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.08))',
        },
      },
    },
  },
  compoundVariants: [
    {
      size: 'md',
      isMinWidth: 'true',
      css: {
        '@md': {
          minWidth: '$size-36',
        },
      },
    },
    {
      size: 'lg',
      isMinWidth: 'true',
      css: {
        '@md': {
          minWidth: '$size-56',
        },
      },
    },
    {
      isTransparent: 'true',
      isIconLarge: 'true',
      css: {
        columnGap: '$space-2',
        '@md': {
          columnGap: '$space-3',
        },
      },
    },
  ],
  defaultVariants: {
    size: 'lg',
    iconPosition: 'leading',
    variant: 'button-red',
  },
});

export interface ButtonProps extends React.ComponentProps<typeof ButtonStyled> {
  children?: React.ReactNode;
  className?: string;
  css?: CSS;
  label?: string;
  href?: string;
  target?: '_self' | '_blank' | '_parent' | '_top';
  icon?: IIcon;
  name?: string;
  prefetch?: boolean;
  onClick?: () => void;
  test?: ITest;
  tracking?: ITracking;
}

export const Button = React.memo(
  ({
    children,
    className,
    css,
    icon,
    label,
    href,
    target,
    name,
    prefetch = false,
    onClick,
    test,
    tracking,
    ...rest
  }: ButtonProps) => {
    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (!href) {
          event.preventDefault();
          onClick?.();
        }
      },
      [href, onClick]
    );

    const getTextVariant = useCallback(() => {
      switch (rest.size) {
        case 'sm':
          return 'text-button-2';
        case 'xl':
          return 'text-body-1';
        default:
          return 'text-button-1';
      }
    }, [rest.size]);

    const isTrailingIcon = useMemo(
      () =>
        ['arrow-forward', 'open-in-new'].includes(icon ?? '') ||
        rest.iconPosition === 'trailing',
      [icon, rest.iconPosition]
    );

    const baseButtonProps = useMemo(
      () => ({
        id: name,
        name: name,
        css,
        className,
        'aria-label': label ?? 'button',
        disabled: rest.disabled,
        size: rest.size,
        variant: rest.variant,
        border: rest.border,
        isShadow: rest.isShadow,
        isLoading: rest.isLoading,
        isPointerNone: rest.isPointerNone,
        isFull: rest.isFull,
        isMinWidth: rest.isMinWidth,
        isIconLarge: rest.isIconLarge,
        isTransparent: rest.isTransparent,
        'data-track': tracking?.dataTrack,
        'data-track-section': tracking?.dataTrackSection,
        'data-track-value': tracking?.dataTrackValue,
        'data-track-text': tracking?.dataTrackText,
        'data-track-url': tracking?.dataTrackUrl,
      }),
      [
        name,
        css,
        className,
        label,
        rest.disabled,
        rest.size,
        rest.variant,
        rest.border,
        rest.isShadow,
        rest.isLoading,
        rest.isPointerNone,
        rest.isFull,
        rest.isMinWidth,
        rest.isIconLarge,
        rest.isTransparent,
        tracking?.dataTrack,
        tracking?.dataTrackSection,
        tracking?.dataTrackValue,
        tracking?.dataTrackText,
        tracking?.dataTrackUrl,
      ]
    );

    return href ? (
      <ButtonStyled
        {...baseButtonProps}
        as={Link}
        href={href}
        prefetch={prefetch}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
        iconPosition={isTrailingIcon ? 'trailing' : 'leading'}
        data-test={test?.dataTest}
      >
        {label ? (
          <>
            {!!icon && <Icon icon={icon} />}
            <Text
              size={getTextVariant()}
              font="bold"
              wrap
              dangerouslySetInnerHTML={{ __html: label }}
            />
          </>
        ) : (
          children
        )}
      </ButtonStyled>
    ) : (
      <ButtonStyled
        {...baseButtonProps}
        as="button"
        onClick={handleClick}
        iconPosition={isTrailingIcon ? 'trailing' : 'leading'}
        data-test={name ? `button_${name}` : test?.dataTest}
        {...rest}
      >
        {label ? (
          <>
            {!!icon && <Icon icon={icon} />}
            <Text
              size={getTextVariant()}
              font="bold"
              wrap
              dangerouslySetInnerHTML={{ __html: label }}
            />
            {!!rest.isLoading && <Loading />}
          </>
        ) : (
          children
        )}
      </ButtonStyled>
    );
  }
);

Button.displayName = 'Button';
