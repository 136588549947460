const OpenInNew = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.46454 5.63604V7.63008L14.9487 7.63715L4.92901 17.6569L6.34322 19.0711L16.3629 9.05136V15.5426L18.364 15.5355V5.63604H8.46454Z" />
  </svg>
);
OpenInNew.displayName = 'IconsOpenInNew';
export default OpenInNew;
